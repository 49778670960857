import axios from "@/axios";

export default {
  /**
   * 数量统计
   * @param {}
   * @returns 
   */
   count(params) {
    return axios({
      url: "/api/screen/statistic/count",
      method: "post",
      data: params
    })
  },
  /**
   * 新增统计
   * @param {}
   * @returns 
   */
   new(params) {
    return axios({
      url: "/api/screen/statistic/new",
      method: "post",
      data: params
    })
  },
  /**
   * 平台统计
   * @param {}
   * @returns 
   */
   platform(params) {
    return axios({
      url: "/api/screen/statistic/platform",
      method: "post",
      data: params
    })
  },
  /**
   * 热门标签
   * @param {}
   * @returns 
   */
   popularTags(params) {
    return axios({
      url: "/api/screen/statistic/popularTags",
      method: "post",
      data: params
    })
  },
  /**
   * 条例区分占比
   * @param {}
   * @returns 
   */
   projectPercent(params) {
    return axios({
      url: "/api/screen/statistic/projectPercent",
      method: "post",
      data: params
    })
  },
  /**
   * 热门事项
   * @param {}
   * @returns 
   */
   hotAspect(params) {
    return axios({
      url: "/api/screen/statistic/hot/aspect",
      method: "post",
      data: params
    })
  }

}
import axios from 'axios';
// Vue.prototype.toast = Toast

// 全局的axios默认值
const Instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false
});
// axios.defaults.headers.put['Content-Type'] = 'application/json';
// 超时设置
// Instance.defaults.timeout = 20000;
// 添加请求拦截器
Instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  let token = getToken('token');
  if (token) {
    config.headers.common['X-Access-Token'] = token;
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
// 添加响应拦截器
Instance.interceptors.response.use(response => {
  // 对响应数据做点什么
  let data = response.data;
  if (data.code === 200 || data.code === 0) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response.data)
  }
}, async error => {
  // if (error.message.includes('timeout') || error.message.includes('Network Error')) {
  //   return Promise.reject(error);
  // }
  return Promise.reject(error);
})

function getToken() {
  try {
    var data = localStorage.getItem("token");
    if (data !== null) {
      return data.replace("#/", "")
    }
    return null
  } catch (e) {
    return null
  }
}

export default Instance;
import axios from "@/axios";

export default {
  /**
   * 热门标签列表
   * @param {}
   * @returns 
   */
  popularTags(params) {
    return axios({
      url: "/api/screen/statistic/popularTags",
      method: "post",
      data: params
    })
  },
  /**
   * 区分列表（不分页）
   * @param {}
   * @returns 
   */
   tagList(params) {
    return axios({
      url: "/api/screen/project/all",
      method: "post",
      data: params
    })
  },
  /**
   * 搜索热词列表
   * @param {}
   * @returns 
   */
   getHotKey(params) {
    return axios({
      url: "/api/screen/aspect/hotKey",
      method: "post",
      data: params
    })
  },
  /**
   * 搜索后的数据列表
   * @param {}
   * @returns 
   */
   getSearchItem(params) {
    return axios({
      url: "/api/screen/aspect/items",
      method: "post",
      data: params
    })
  },
  /**
   * 搜索后的数据列表(图片)
   * @param {}
   * @returns 
   */
   getSearchItemImage(params) {
    return axios({
      url: "/api/screen/aspect/items/image",
      method: "post",
      data: params
    })
  },
  /**
   * 条例详情
   * @param {}
   * @returns 
   */
   getSearchItemDetail(params) {
    return axios({
      url: "/api/screen/aspect/detail",
      method: "post",
      data: params
    })
  },
  

}
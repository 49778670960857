<template>
  <div class="loading-div">
    <a-spin tip="加载中...">
      <a-icon slot="indicator" type="loading" style="font-size: 5vh" spin size="large"/>
    </a-spin>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
    };
  },
  components: {
    
  },
  props: {
    
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped>
.loading-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-div >>>.ant-spin.ant-spin-show-text .ant-spin-text {
  font-size: 2.3vh;
  margin-top: 2vh;
  font-family: 'YouSheBiaoTiHei';
}
</style>
// import storage from 'store'
// import Vue from 'vue'
// import {
//   login,
//   logout,
// } from '@/api/login'

const user = {
  state: {
    token: '',
    name: '',
    avatar: '',
    info: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
  },

  actions: {
    // 保存用户基本信息
    setInfo({
      state,
      commit,
      dispatch
    }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_INFO', Object.assign({}, state.info, params));
        resolve();
      })
    },
    // 登录
    // Login({
    //   commit
    // }, userInfo) {
    //   return new Promise((resolve, reject) => {
    //     login(userInfo).then(response => {
    //       console.log(response)
    //       const result = response
    //       commit('SET_TOKEN', result.token)
    //       commit('SET_REFRESH_TOKEN', result.refresh_token)
    //       commit('SET_REFRESH_TOKEN_EXPIRE_AT', result.refresh_token_expire_at)
    //       resolve(response)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // 登出
    // Logout({
    //   commit,
    //   state
    // }) {
    //   return new Promise((resolve) => {
    //     logout(state.token).then(() => {
    //       commit('SET_TOKEN', '')
    //       commit('SET_INFO', {})
    //       resolve()
    //     }).catch(() => {
    //       resolve()
    //     }).finally(() => {})
    //   })
    // },

  }
}

export default user

const routes = [
  // {
  //   path: '/',
  //   name: 'index',
  //   component: () => import('../views/index.vue'),
  //   meta: {
  //     title: '首页'
  //   }
  // },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/search.vue'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/analyse',
    name: 'analyse',
    component: () => import('../views/analyse/analyse.vue'),
    meta: {
      title: '分析台'
    }
  },
]
export default routes;
<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
export default {
  data() {
    return {
      zh_CN,
    };
  },
  computed: {},
  created() {
    localStorage.setItem('token', this.rondomPass(10))
  },
  methods: {
    //随机数生成
    rondomPass(number){
      var arr = new Array;
      var arr1 = new Array("0","1","2","3","4","5","6","7","8","9");
      var nonceStr=''
      for(var i=0;i<number;i++){
          var n = Math.floor(Math.random()*10);
          arr[i] =arr1[n] ;
          nonceStr+=arr1[n];
      }
      return nonceStr
    }
  },
};
</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100vh;
  // background-color: #020308;
  background: linear-gradient(to bottom, #052e59, #021931);
  overflow: hidden;
  box-sizing: border-box;
}
</style>

import router from './router'
import { familyTree } from '@/utils/util'


const defaultRoutePath = '/search'
const routerArr = familyTree(router.options.routes)//获取所有页面路径
router.beforeEach((to, from, next) => {
  /* has token */
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if(routerArr.includes(to.path)){
    next()
  }else{
    next({
      path: defaultRoutePath
    })
  }
})

router.afterEach(() => {
  
})
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router);

// 路由配置
const router = new Router({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return savedPosition;
      } else {
         return {x: 0, y: 0};
      }
   }
});

router.beforeEach((to, from, next) => {
   next();
});

router.afterEach((to, from, next) => {
   window.scrollTo(0, 0);
});

export default router;